{
  "name": "enrollhere-importer",
  "version": "0.3.6",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "serve:ssr:enrollhere-importer": "node dist/enrollhere-importer/server/server.mjs",
    "deploy-major": "npm version major && git add . && git push --tags && git push origin main:main",
    "deploy-minor": "npm version minor && git add . && git push --tags && git push origin main:main",
    "deploy": "npm version patch && git add . && git push --tags && git push origin main:main",
    "replace-timestamp": "node ./replace.build.js",
    "deploy:prod:temp": " ng build --c production &&  firebase deploy --only hosting:enrollhere-importer --project enroll-here --config firebase.temp.json",
    "deploy:demo:temp": "mv ./src/environments/environment.ts ./src/environments/environment.prod.ts  && cp ./src/environments/environment.demo.ts ./src/environments/environment.ts && ng build --c production &&  firebase deploy --only hosting:enrollhere-importer --project enroll-here-demo --config firebase.temp.json && mv ./src/environments/environment.prod.ts ./src/environments/environment.ts",
    "deploy:dev:temp": "mv ./src/environments/environment.ts ./src/environments/environment.prod.ts  && cp ./src/environments/environment.development.ts ./src/environments/environment.ts && ng build --c production &&  firebase deploy --only hosting:enrollhere-importer --project enroll-here-dev --config firebase.temp.json && mv ./src/environments/environment.prod.ts ./src/environments/environment.ts",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org enrollhere --project enrollhere-importer ./dist/enrollhere-importer/browser && sentry-cli sourcemaps upload --org enrollhere --project enrollhere-importer ./dist/enrollhere-importer/browser"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.7",
    "@angular/common": "^18.2.7",
    "@angular/compiler": "^18.2.7",
    "@angular/core": "^18.2.7",
    "@angular/fire": "^18.0.1",
    "@angular/forms": "^18.2.7",
    "@angular/platform-browser": "^18.2.7",
    "@angular/platform-browser-dynamic": "^18.2.7",
    "@angular/platform-server": "^18.2.7",
    "@angular/router": "^18.2.7",
    "@angular/service-worker": "^18.2.7",
    "@angular/ssr": "^18.2.7",
    "@fortawesome/angular-fontawesome": "^0.15.0",
    "@fortawesome/pro-regular-svg-icons": "^6.6.0",
    "@ngrx/effects": "^18.0.2",
    "@ngrx/store": "^18.0.2",
    "@ngrx/store-devtools": "^18.0.2",
    "@sentry/angular": "^8.33.1",
    "@sentry/cli": "^2.36.6",
    "any-date-parser": "^1.5.4",
    "express": "^4.21.0",
    "fast-levenshtein": "^3.0.0",
    "lottie-web": "^5.12.2",
    "ngx-cookie-service": "^18.0.0",
    "ngx-infinite-scroll": "^18.0.0",
    "read-excel-file": "^5.8.6",
    "replace-in-file": "^8.2.0",
    "rxjs": "~7.8.1",
    "ssr-window": "^4.0.2",
    "tslib": "^2.7.0",
    "uuid": "^10.0.0",
    "zone.js": "~0.14.4"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.7",
    "@angular/cli": "^18.2.7",
    "@angular/compiler-cli": "^18.2.7",
    "@ngrx/schematics": "^18.0.2",
    "@types/express": "^5.0.0",
    "@types/fast-levenshtein": "^0.0.4",
    "@types/jasmine": "~5.1.4",
    "@types/node": "^22.7.4",
    "@types/uuid": "^10.0.0",
    "autoprefixer": "^10.4.20",
    "daisyui": "^4.12.12",
    "jasmine-core": "~5.3.0",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "postcss": "^8.4.47",
    "prettier": "3.3",
    "tailwindcss": "^3.4.13",
    "typescript": "~5.4.5",
    "webpack-bundle-analyzer": "^4.10.2"
  }
}
